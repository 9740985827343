<template>
  <v-tooltip top content-class="custom-top-tooltip">
    <template v-slot:activator="{ on, attrs }">
      <v-icon :small="small" v-if="questionMark" class="mx-2" v-bind="attrs" v-on="on"
        >mdi-progress-question</v-icon
      >
    </template>
    <span>
      <slot name="text"></slot>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "tooltip-question",
  title: "Tooltip Question",
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    questionMark: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
